import $ from 'jquery';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick.js';
import 'lightgallery/dist/js/lightgallery.min';
import 'lightgallery/modules/lg-fullscreen.min';
import 'lightgallery/modules/lg-thumbnail.min';
import 'lightgallery/lib/jquery.mousewheel.min';
import 'jquery-viewport-checker/src/jquery.viewportchecker';
import 'eonasdan-bootstrap-datetimepicker';
import 'bootstrap-datepicker/dist/css/bootstrap-datepicker.min.css';
import 'bootstrap-datepicker/dist/js/bootstrap-datepicker.min';
import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import '../styles/index-inner.css';
import 'jquery-confirm/js/jquery-confirm.js'
import 'jquery-confirm/css/jquery-confirm.css'

const dir = "rtl";
$(document).ready(function (e) {

});

